<template>
  <div :key="updateKey">
    <achievements-section
      :achievements="achievements"
      :sort="achievementsSort"
      :status="achievementsStatus"
      :user-status="achievementsUserStatus"
      :brand="achievementsBrand"
      :season="achievementsSeason"
      :is-empty-filter="isEmptyAchFilter"
      @setPage="getAchievements"
      @setSort="handleSetSort"
      @setFilter="handleSetFilter"
      @clearFilters="clearFilters"
      @openTeamDialog="openTeamDialog"
    />
    <team-dialog
      v-model="teamDialog"
      :team-id="selectedTeam"
      @openProjectDialog="openProjectDialog"
    />

    <project-dialog v-model="projectDialog" :project-id="selectedProject" />
  </div>
</template>
<script>
import { AchievementsSection } from "@/components/contact/sections";
import { initialListingModel } from "@/utils";
import { talentClient } from "@/api";
import { parseAchivement } from "@/api/utils/dataParsers";
import TeamDialog from "@/components/dialogs/TeamDialog";
import ProjectDialog from "@/components/dialogs/ProjectDialog";
import dayjs from "@/plugins/dayjs";

const INITIAL_ORDERING = "-created_at";
export default {
  name: "ContactAchievementsPage",
  components: {
    AchievementsSection,
    TeamDialog,
    ProjectDialog,
  },
  data() {
    return {
      achievements: initialListingModel(15, { ordering: INITIAL_ORDERING }),
      achievementsSort: INITIAL_ORDERING,
      achievementsStatus: null,
      achievementsUserStatus: null,
      achievementsBrand: null,
      achievementsSeason: null,
      teamDialog: false,
      selectedTeam: null,
      selectedProject: null,
      projectDialog: false,
      updateKey: 0,
    };
  },
  metaInfo() {
    return {
      title: `Карточка контакта / Достижения`,
    };
  },
  computed: {
    isEmptyAchFilter() {
      return !(
        this.achievementsUserStatus ||
        this.achievementsStatus ||
        this.achievementsBrand ||
        this.achievementsSeason
      );
    },
    talentId() {
      return +this.$route.params.id;
    },
  },
  activated() {
    this.getAchievements(this.achievements.page);
    this.updateKey += 1;
  },
  methods: {
    async getAchievements(page = 1) {
      if (this.achievements.pending) return;
      const {
        achievementsSort,
        achievementsStatus,
        achievementsUserStatus,
        achievementsBrand,
        achievementsSeason,
      } = this;
      const params = {
        ...this.achievements.params,
        ordering: achievementsSort,
        ...(achievementsStatus && { status: achievementsStatus }),
        ...(achievementsUserStatus && { roles: achievementsUserStatus }),
        ...(achievementsBrand && { brands: achievementsBrand }),
      };

      if (achievementsSort.includes("event__title")) {
        params.ordering = `${achievementsSort},created_at`;
      }

      if (achievementsSeason) {
        params.start_after = dayjs(`${achievementsSeason}-01-01`).format();
        params.end_before = dayjs(`${+achievementsSeason + 2}-01-01`).format();
      }

      const { talentId } = this;
      this.achievements.error = "";
      this.achievements.pending = true;
      try {
        const { data } = await talentClient({
          method: "GET",
          url: `/users/${talentId}/achievements/`,
          params: {
            limit: this.achievements.limit,
            offset: (page - 1) * this.achievements.limit,
            ...this.achievements.params,
            ...(params && {
              ...params,
            }),
          },
        });
        const list = data.results.map(parseAchivement);
        this.achievements = {
          ...this.achievements,
          list,
          total: data.count,
          pagesCount: Math.ceil(data.count / this.achievements.limit),
          page,
        };
      } catch (error) {
        this.achievements.error = error.message;
      }
      this.achievements.pending = false;
    },
    handleSetSort(sort) {
      this.achievementsSort = sort;
      this.getAchievements(1);
    },
    handleSetFilter(filter) {
      if (filter) {
        this[filter.key] = filter.value;
      }
      this.getAchievements(1);
    },
    clearFilters() {
      this.achievementsStatus = null;
      this.achievementsSeason = null;
      this.achievementsUserStatus = null;
      this.achievementsBrand = null;
      this.getAchievements(1);
    },
    openTeamDialog(teamId) {
      this.selectedTeam = teamId;
      this.teamDialog = true;
    },
    openProjectDialog(projectId) {
      this.selectedProject = projectId;
      this.projectDialog = true;
    },
  },
};
</script>
